.swiper-button-next, .swiper-button-prev {
  background-image: url("./images/arrowIcon.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  width: 40px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.swiper-button-next {
  transform: rotate(180deg);
}

.swiper-pagination, .swiper-button-next, .swiper-button-prev {
  opacity: 0;
  transition: opacity .3s;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-pagination,
.swiper:hover .swiper-button-prev {
  opacity: 1;
}

.swiper-button-next::after,
.swiper-button-prev::after{
  display: none;
}

.swiper-pagination-bullet {
  opacity: 0.5;
  background-color: white;
  margin: 0 2px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
